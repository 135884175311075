import React from "react";
import horizontalImg from "../../resources/img/horizontalGroup.png";
import verticalImg from "../../resources/img/verticalGroup.png";
const Fold3 = () => {
  return (
    <div className="darkBg padding10vhtop padding10vhbottom">
      <div className="container ">
        <div className="row d-flex justify-content-center mt-5 pt-3">
          <div className="col-lg-6 d-flex flex-column justify-content-center ">
            <h1 className="fold3-source-content-title ">
              THE STANDARD & POOR’S RATINGS SERVICES GLOBAL FINANCIAL LITERACY
              SURVEY
            </h1>
          </div>
        </div>
        <div className="row showOnBig mt-5 mb-5 pt-3">
          <div className="col img-container d-flex justify-content-center mt-5 mb-5">
            <img src={horizontalImg} alt="source" className="img-fluid" />
          </div>
        </div>

        <div className="row showOnSmall mt-5">
          <div className="col img-container d-flex justify-content-center">
            <img src={verticalImg} alt="source" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fold3;
