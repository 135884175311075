import React from "react";
import OurWhyCard from "./OurWhyCard";
import img1 from "../../resources/img/owImg1.webp";
import img2 from "../../resources/img/owImg2.webp";
import img3 from "../../resources/img/owImg3.webp";
import grayLine from "../../resources/img/grayLine.svg";
import gradientLine from "../../resources/img/gradientLineNoGlow.svg";
import glow from "../../resources/img/bigCardGlow2.svg";
const OurWhyBigCard = () => {
  return (
    <div className="row position-relative ">
      <div className="col ">
        <div className="ourwhyBigCard d-flex flex-column gap-4 ">
          <div className="showOnBig z-index2">
            <div className="glowContainer">
              <div className="glowFoldBigCard"></div>
              <img src={glow} loading='lazy' alt="alt" className="glowPositionBigCard"></img>
            </div>
            <div className="row d-flex justify-content-center ">
              <div className="col">
                <OurWhyCard
                  description={
                    "Built on a goal-based framework, we craft investing education that matters to you. We continuously monitor the latest trends to deliver relevant content.                    "
                  }
                  img={img1}
                />
              </div>
              <div className="col">
                <OurWhyCard
                  description={
                    "We screen financial markets for trending themes and under-the-radar opportunities. Our expert team develops unbiased investing insights. Assessed objectively. Delivered with clarity."
                  }
                  img={img2}
                />
              </div>

              <div className="col">
                <OurWhyCard
                  description={
                    "Our diverse team of creative storytellers bring our vibrant content to life. Designed for audiences across generations. Straight from the Beurzbyte Studio to our mobile app.                    "
                  }
                  img={img3}
                />
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-center position-relative z-index2">
            <div className="col-lg-12 d-flex justify-content-center">
              <h1 className="bigCardTitle">
                We believe everyone is an investor.<br></br>Including you.
              </h1>
            </div>
          </div>
          <div className="row showOnBig">
            <div className="col d-flex justify-content-center showOnBig">
              <img src={grayLine} loading='lazy' alt="grayLine" />
            </div>
          </div>
          <div className="row showOnSmall">
            <div className="col d-flex justify-content-center img-container ">
              <img src={gradientLine} loading='lazy' alt="gradient" className="img-fluid" />
            </div>
          </div>
          <div className="row d-flex justify-content-center z-index2">
            <div className="col-lg-7 d-flex justify-content-center">
              <h2 className="bigCardDescription">
                We’re on a mission to make financial education and investment
                insights accessible to all. Grow your confidence. Make smarter
                financial decisions. Discover the investor in you.
              </h2>
            </div>
          </div>
          <div className="row d-flex justify-content-center z-index2">
            <div className="col d-flex justify-content-center">
              <div className="ourWhyB-gradient">
                <div className="ourWhyB-gradient-wrap">
                  <a className="ourWhyB " href="/ourwhy">
                    Our Why
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurWhyBigCard;
