
import downloadbutton from "../resources/img/download-button.svg";
import downloadAndroid from "../resources/img/download-Android.svg";

function ActionsButtons({ }) {
    return (
        <div className="header-actions d-flex gap-3">
            <a href="https://apps.apple.com/us/app/beurzbyte/id6443670820">
                <img
                    src={downloadbutton}
                    className="download-button"
                    alt="menu"
                />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.beurzbyte.android">
                <img
                    src={downloadAndroid}
                    className="download-button"
                    alt="menu"
                />
            </a>
        </div>
    );
}
export default ActionsButtons;
