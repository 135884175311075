import React, { useState } from "react";
import phoneFrame2 from "../resources/img/PhoneFrame2.webp";
import ReviewCard from "./Cards/ReviewCard";
import decoreLT from "../resources/img/skew2Decore.png";
import decoreLB from "../resources/img/skew2Decore-bottom.png";
import avatar1 from "../resources/img/avatar1.png";
import avatar2 from "../resources/img/avatar2.png";
import avatar3 from "../resources/img/avatar3.png";
const Skew2 = () => {
  const reviews = [
    {
      name: "Amaury V.",
      position: "University business student",
      avatar: avatar3,
      description:
        "Beurzbyte Learn allows me to start learning about investing for free. In school we never really learned in depth about finance and investing so it's nice that I can now learn more about investing in my own time without having to pay big bucks. The video content is also very enjoyable and fun to watch! To all students, I definitely recommend you to try Beurzbyte Learn.",
    },
    {
      name: "Jurgen A.",
      position: "Entrepreneur/CEO",
      avatar: avatar2,
      description:
        "Beurzbyte Discover allows me to always stay informed about unique investment opportunities. Due to my busy schedule, I never really had time to look at multiple opportunities in different asset classes. The great thing about Discover is that it is in video content and a story is immediately told behind every investment. I will definitely continue to use Discover!",
    },
    {
      name: "Tina S.",
      position: "Doctor",
      avatar: avatar1,
      description:
        "As a medical professional, the world of investing and finance can seem quite foreign and hard to navigate. Beurzbyte has filled in the gaps in my knowledge and provided me with an insight into all the current trends. This has empowered the investor in me, to be able to access such information which I can integrate with my passions and interests.",
    },
  ];
  const [counter, setCounter] = useState(reviews.length);

  return (
    <div className="darkBg  position-relative z-index1 padding10vhtop padding10vhbottom">
      <div className="container ">
        <div className="row d-flex justify-content-center rev  ">
          <div className="container col-lg-6 position-relative   d-flex justify-content-center align-items-center  ">
            <div className="col z-index2  d-flex justify-content-center align-items-center position-relative">
              {reviews.slice(0, counter).map((item, index) => (
                <ReviewCard
                  key={index}
                  increment={() => setCounter(counter + 1)}
                  decrement={() => setCounter(counter - 1)}
                  position={index}
                  name={item.name}
                  title={item.position}
                  avatar={item.avatar}
                  description={item.description}
                  counter={counter}
                />
              ))}
            </div>
          </div>

          <div className="col img-container d-flex justify-content-center mt-5 align-items-center position-relative">
            <img src={phoneFrame2} loading='lazy' alt="phone" className=" img-fluid" />
          </div>
        </div>
      </div>
      <div className="showOnBig skew2DecoreTop">
        <img src={decoreLT} alt="" />
      </div>
      <div className="showOnBig skew2DecoreBottom">
        <img src={decoreLB} alt="" />
      </div>
      <div id="discover"></div>
    </div>
  );
};

export default Skew2;
