import header from "../resources/img/header.webp";
import ActionsButtons from './ActionButtons';

export default function FoldOne() {
  return (
    <div className="fold1">
      <video controls id="video" allowfullcreen="true">
        <source
          src="https://beurzbyte-production.s3.eu-west-2.amazonaws.com/static-files/website-video.mp4"
          type="video/mp4"
        ></source>
      </video>

      <div className="container">
        <div className='container'>
        <div className="row">
          <div className="col-lg-5">
            {/* <div className="comming-soon">COMING SOON</div> */}
            <div className="title-container">
              <h1 className="bb-title">
                The <span className="line-decoration bb-title">only guide</span>{" "}
                you need to empower your investment decisions
              </h1>
            </div>

            <div className="header-image-small">
              <img
                src={header}
                width="auto"
                height="auto"
                className="img-fluid header-image-small"
                alt="header"
                fetchpriority="high"
              />
            </div>

            <div className="header-description-container">
              <p>
                We believe everyone is an investor. <br></br>
                <b>Including you</b>.
              </p>
              <br></br>
              <p>
                Tap into actionable investing education and unbiased investing
                insights. At your own pace. Powered by our team of world-class
                investment professionals. All in premium video content. Easy to
                understand. Inspiring to watch.
              </p>
            </div>

            <div className='mt-5 mb-5'>
            <ActionsButtons />
            </div>
          </div>

          <div className="col-lg-7">
            <img src={header}                 width="auto"
                height="auto" fetchpriority="high" className="img-fluid header-image" alt="header" />
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}
