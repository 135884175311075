import React from "react";

import finextra from "../../resources/img/trusted-industry/finExtra.png";
import detjid from "../../resources/img/trusted-industry/deTijd.png";
import techeu from "../../resources/img/trusted-industry/techeu.png";
import tfn from "../../resources/img/trusted-industry/tfn.png";
import edtech from "../../resources/img/trusted-industry/edtech.png";
import ControlledCarousel3 from "../ControlledCarousel3";

const Fold2 = ({ bg }) => {
  const carouselContent = [
    <div className="col d-flex justify-content-center align-items-center img-container">
      <a href="/press">
        {" "}
        <img src={finextra} className="img-fluid " alt="trusted"></img>
      </a>
    </div>,
    <div className="col d-flex justify-content-center align-items-center img-container">
      <a href="/press">
        {" "}
        <img src={detjid} className="img-fluid " alt="trusted"></img>
      </a>
    </div>,
    <div className="col d-flex justify-content-center align-items-center img-container">
      <a href="/press">
        {" "}
        <img src={techeu} alt="trusted" className="img-fluid" />
      </a>
    </div>,
    <div className="col d-flex justify-content-center align-items-center img-container">
      <a href="/press">
        {" "}
        <img src={tfn} alt="trusted" className="img-fluid" />
      </a>
    </div>,
    <div className="col d-flex justify-content-center align-items-center img-container">
      <a href="/press">
        {" "}
        <img src={edtech} alt="trusted" className="img-fluid" />
      </a>
    </div>,
  ];
  return (
    <div className={bg}>
      <div className="container p-lg-5 p-3">
        <div className="row d-flex justify-content-center mt-5">
          <div className="col-8 d-flex justify-content-center">
            <h1 className="featured-title">AS FEATURED IN</h1>
            <span id="learn"></span>  
          </div>
        </div>
        <div className="row showOnBig mt-5  mb-5  ">
          <div className="col  justify-content-center img-container">
            <a href="/press">
              {" "}
              <img
                src={finextra}
                className="img-fluid"
                alt="trusted"
              ></img>{" "}
            </a>
          </div>
          <div className="col   justify-content-center img-container ">
            <a href="/press">
              {" "}
              <img src={detjid} className="img-fluid" alt="trusted"></img>
            </a>
          </div>
          <div className="col   justify-content-center img-container ">
            <a href="/press">
              {" "}
              <img src={techeu} className="img-fluid" alt="trusted" />
            </a>
          </div>
          <div className="col   justify-content-center img-container  ">
            <a href="/press">
              {" "}
              <img src={tfn} className="img-fluid" alt="trusted" />
            </a>
          </div>
          <div className="col   justify-content-center img-container  ">
            <a href="/press">
              {" "}
              <img src={edtech} className="img-fluid" alt="trusted" />
            </a>
          </div>
        </div>
        <div className="showOnSmall">
          <div className="row d-flex justify-content-center  mb-5 workCarouselHeight">
            <ControlledCarousel3 content={carouselContent} variant={"black"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fold2;
