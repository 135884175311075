import React from "react";
import Articles from "./Articles";
import Fold1 from "./Fold1";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const Presspage = () => {
  const location = useLocation();

  React.useEffect(() => {
    // runs on location, i.e. route, change
    ReactGA.set({ page: location.pathname });
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return (
    <>

      <Fold1 />
      <Articles />



    </>
  );
};

export default Presspage;
