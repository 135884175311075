import React, { useState } from "react";

import subscribeIphone from "../resources/img/subscribeIphone.webp";
import $ from "jquery";
import jsonp from "jsonp";
import toQueryString from "to-querystring";

const getAjaxUrl = (url) => url.replace("/post?", "/post-json?");

const SubscribeToOurNewsletter = ({ theme }) => {
  const [input, setInput] = useState("");
  const [isSent, setIsSent] = useState(false);

  const onSubmitForm = () => {
    let emailValue = $("#subscriber-email").val();
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(emailValue)) {
      const link =
        "https://beurzbyte.us9.list-manage.com/subscribe/post?u=f15bcbcee2a5087510ab48253&amp;id=9349a0ef6f&amp;f_id=00b222e1f0";

      const params = toQueryString({ EMAIL: emailValue });
      const url = getAjaxUrl(link) + "&" + params;

      $("#subscribe-button").toggle();

      jsonp(
        url,
        {
          param: "c",
        },
        (err, data) => {
          $("#subscribe-button").toggle();

          if (err) {
            alert("Something went wrong, please try again");
          } else if (data.result !== "success") {
            alert("Something went wrong, please try again");
          } else {
            setIsSent(true);
          }
        }
      );
    } else {
      // invalid email, maybe show an error to the user.
      alert("You need to enter a valid email address");
    }
  };

  return (
    <>
      {isSent ? (
        <div className={`container subscribe-container p-lg-5 p-5 ${theme}`}>
          <div className="row rev">
            <div className="col-lg-5 d-flex justify-content-center">
              <img
                src={subscribeIphone}
                alt=""
                className="subscribeIphone"
              ></img>
            </div>

            <div className="col subscribe-container-right">
              <div className="row ">
                <div className="col">
                  <h1 className={`subscribe-title ${theme} `}>Thank you!</h1>
                </div>
              </div>
              <div className="row">
                <div className="col ">
                  <p className={`subscribed-p ${theme}`}>
                    You’ve subscribed to our newsletter!
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p className={`subscribed-p-small ${theme}`}>
                    You’ll receive insights and stay up-to-date with our latest
                    news and product updates.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={`container subscribe-container p-lg-5 p-5 ${theme}`}>
          <div className="row rev">
            <div className="col-lg-5 d-flex justify-content-center">
              <img
                src={subscribeIphone}
                alt=""
                loading='lazy'
                className="subscribeIphone"
              ></img>
            </div>

            <div className="col subscribe-container-right ">
              <div className="row ">
                <div className="col">
                  <h1 className={`subscribe-title ${theme}`}>
                    {" "}
                    Subscribe to our <br></br> Newsletter
                  </h1>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p className={` subscribe-p ${theme}`}>
                    Get insights straight to your mailbox. Stay up-to-date with
                    our latests news and product updates.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col d-flex w-100 position-relative d-flex align-items-center">
                  <input
                    id="subscriber-email"
                    className="w-100 subscribe-input"
                    placeholder="Enter your email address"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                  ></input>
                  <button
                    id="subscribe-button"
                    className="subscribe-button"
                    onClick={onSubmitForm}
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SubscribeToOurNewsletter;
