import React from "react";
import Fold1 from "./Fold1";
import Fold2 from "./Fold2";
import Fold3 from "./Fold3";
import Fold4 from "./Fold4";
import OwSkew1 from "./OwSkew1";
import OwSkew2 from "./OwSkew2";
import OwSkewContainer from "./OwSkewContainer";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const OurWhyPage = () => {
  const location = useLocation();

  React.useEffect(() => {
    // runs on location, i.e. route, change
    ReactGA.set({ page: location.pathname });
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return (
    <>
      <Fold1 />
      <Fold2 />
      <OwSkewContainer backGround={"ow-dark"}>
        <OwSkew2 />
      </OwSkewContainer>
      <Fold3 />
      <OwSkewContainer backGround={"ow-light"}>
        <OwSkew1 />
      </OwSkewContainer>
      <Fold4 />
    </>
  );
};

export default OurWhyPage;
