import React from 'react'
import decore2 from "../../resources/img/workDecore2.svg"
import WorkinfoCard from '../Cards/WorkinfoCard'

import avatar1 from "../../resources/img/workCardsIcons/avatar1.png"
import avatar2 from "../../resources/img/workCardsIcons/avatar2.png"
import avatar3 from "../../resources/img/workCardsIcons/avatar3.png"
import avatar4 from "../../resources/img/workCardsIcons/avatar4.png"
import avatar5 from "../../resources/img/workCardsIcons/avatar5.png"
import avatar6 from "../../resources/img/workCardsIcons/avatar6.png"
import ControlledCarousel from '../ControlledCarousel'
const Fold5 = () => {
    const obj = [{
        img: avatar1,
        text: "Equip yourself with the ultimate investing blueprint to kickstart your investment journey.        ",
        title: "The ultimate investing blueprint"
    },
    {
        img: avatar2, text: "Explore what investing means and how you can reach your investment goals.",
        title: "What is investing?"
    },
    {
        img: avatar3, text: "Build your knowledge on stocks from the ground up with our jargon buster series.",
        title: "Jargon Buster: Stocks"
    },
    {
        img: avatar4, text: "Explore what to look for to find the right broker for you.",
        title: "Brokerage 101"
    },
    {
        img: avatar5, text: "Understand what drives interest rates and how it impacts your financial decisions.",
        title: "Demystify interest rates"
    },
    {
        img: avatar6, text: "Discover how investor psychology works and how it can improve your investment strategy.",
        title: "The modern intelligent investor"
    }
    ]

    const carouselContent = obj.map((item, index) => {
        return <WorkinfoCard img={item.img} text={item.text} key={index} title={item.title} />
    })
    return (
        <div className='overflow-hidden'>
            <div className='work-bg1 padding10vhtop padding10vhbottom position-relative'>
                <div className='container'>
                    <div className='work-decoration2'>
                        <img src={decore2} alt=""></img>
                    </div>

                    <div className='row mt-5'>
                        <div className='col'>
                            <h1 className='work-review-title'>EMPOWER YOUR EMPLOYEES</h1>
                        </div>
                    </div>
                    <div className='row d-flex mt-4 '>
                        <div className='col gap-3 d-flex align-items-top'>

                            <h2 className='work-dRow'>
                                Equip your employees with the right knowledge <br>
                                </br>and tools to back their financial decisions.
                            </h2>
                        </div>

                    </div>
                    <div className='row gap-3 showOnBig mt-4 padding10vhbottom'>
                        <div className='col  mt-4'>
                            <WorkinfoCard img={obj[0].img} text={obj[0].text} title={obj[0].title} />
                        </div>
                        <div className='col  mt-4'>
                            <WorkinfoCard img={obj[1].img} text={obj[1].text} title={obj[1].title} />
                        </div>
                        <div className='col  mt-4'>
                            <WorkinfoCard img={obj[2].img} text={obj[2].text} title={obj[2].title} />
                        </div>

                        <div className='col  mt-4'>
                            <WorkinfoCard img={obj[3].img} text={obj[3].text} title={obj[3].title} />
                        </div>
                        <div className='col  mt-4'>
                            <WorkinfoCard img={obj[4].img} text={obj[4].text} title={obj[4].title} />
                        </div>
                        <div className='col  mt-4'>
                            <WorkinfoCard img={obj[5].img} text={obj[5].text} title={obj[5].title} />
                        </div>

                    </div>
                    <div className='row showOnSmall padding10vhbottom mt-3'>
                        <ControlledCarousel content={carouselContent} variant={"dark"} />
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Fold5