import React from "react";

const UnlockBenefitCardSmall = () => {
  const openCallSimple = () => {
    let url = "https://calendly.com/pedram-parhizkari/";
    var win = window.open(url, "_blank");
    win.focus();
  };

  return (
    <div className="container">
      <div className="work-benefit-right-small ">
        <div className="">
          <h1 className="work-benefit-title">Try Beurzbyte at work</h1>
          <p className="work-benetif-description mt-sm-4  mt-lg-3 ">
            The only guide your employees need to empower their investment
            decisions.{" "}
          </p>
        </div>

        <div className="work-benefit-bottom d-flex align-items-center justify-content-between mt-5">
          <h1 className="showOnBig ">Price upon request</h1>
          <button className="" onClick={openCallSimple}>
            Book a call
          </button>
        </div>
      </div>
    </div>
  );
};

export default UnlockBenefitCardSmall;
