import React from "react";
import SubscribeToOurNewsletter from "../SubscribeToOurNewsletter";

const Skew2 = () => {
  const openCallSimple = () => {
    let url = "https://calendly.com/pedram-parhizkari/";
    var win = window.open(url, "_blank");
    win.focus();
  };

  return (
    <div className="work-skew ">
      <div className=" work-skew-content padding10vhbottom padding10vhtop">
        <div className="container ">
          <div className="row">
            <div className="col-lg-8">
              <h1 className="color-white bb-title">
                We believe everyone is an investor. Including your employees.
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <p className="color-white mt-4 bb-subtitle ">
                Encourage your employees to make confident, well-informed, and
                smarter financial decisions.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col mt-4">
              <button className="pressBtn " onClick={openCallSimple}>
                Book a call
              </button>
            </div>
          </div>
          <div className="row d-flex align-items-center justify-content-center padding10vhtop  mt-5">
            <div className="col mt-5">
              <SubscribeToOurNewsletter />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skew2;
