import React from "react";
import SkewContainer from "../SkewContainer";
import Fold1 from "./Fold1";
import Fold2 from "./Fold2";
import Fold3 from "./Fold3";
import Fold4 from "./Fold4";
import Skew1 from "./Skew1";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import Fold5 from "./Fold5";
import Skew2 from "./Skew2";

const AtWorkPage = () => {
  const location = useLocation();

  React.useEffect(() => {
    // runs on location, i.e. route, change
    ReactGA.set({ page: location.pathname });
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return (
    <>
      <Fold1 />
      <Fold2 bg={"work-featured-bg"} />
      <Fold3 />
      <SkewContainer>
        <Skew1 />
      </SkewContainer>
      <Fold4 />
      <Fold5 />
      <Skew2 />

    </>
  );
};

export default AtWorkPage;
