import React, { useEffect, useState } from "react";
import line from "../resources/img/decore2.svg";
import InfoCard from "./Cards/InfoCard";
import rocketIcon from "../resources/img/rocketIcon.svg";
import phoneAsset from "../resources/img/learn-asset.webp";
import SmallInfoCard from "./Cards/SmallInfoCard";
import ControlledCarousel from "./ControlledCarousel";
import hatIcon from "../resources/img/universityHat.svg";
import moneyGroupIcon from "../resources/img/moneyGroup.svg";
const Skew1 = () => {
  const carouselContent = [
    <SmallInfoCard
      title={"Investing education that makes sense."}
      description={
        "Understand the science and art of investing. Empower your decisions with affordable investing education. Crafted by investors for investors."
      }
      icon={hatIcon}
    />,
    <SmallInfoCard
      title={"Become a confident investor."}
      description={
        "  Build your investment knowledge from the ground up. Make confident investment decisions that fuel your returns. No more guesswork. Whether you’re a starter or a pro."
      }
      icon={moneyGroupIcon}
    />,
    <SmallInfoCard
      title={"Simple. Engaging. Actionable."}
      description={
        "Explore a comprehensive range of financial topics. Learn at your own pace and on the go. Powerful storytelling in premium video content. Easy to understand. Inspiring to watch."
      }
      icon={rocketIcon}
    />,
  ];
  const titles = [
    "How to invest",
    "Investment Basics",
    "Asset Classes",
    "Strategies",
    "Investor Psychology",
  ];
  const [count, setCount] = useState(0);

  const changeTitle = () => { };
  useEffect(() => {
    const timer = setTimeout(() => {
      if (count !== titles.length - 1) {
        setCount(count + 1);
      } else {
        setCount(0);
      }
    }, 1500);
  }, [count]);
  return (
    <div className="lightBg pt-5 pb-5">
      <div className="container pb-5">
        <div className="row">
          <div className="col d-flex ">
            <h1 className="skewTitle ">
              Learn <span className="showOnSmall"></span>{" "}
              <span className="purple1">{titles[count]}</span>
            </h1>
          </div>
        </div>

        <div className="row ">
          <div className="col-1">
            <img src={line} alt="line"></img>
          </div>
        </div>

        <div className="row">
          <div className="col  mt-2">
            <h2 className="skewDescription">
              Start your investing education journey with
              <span className="bld"> Beurzbyte Learn.</span>
            </h2>
          </div>
        </div>

        <div className="row d-flex justify-content-center ">
          <div className="col-lg-6 ">
            <div className="showOnBig">
              <div className="row mt-5">
                <div className="col mb-4 ">
                  <InfoCard
                    title={"Investing education that makes sense."}
                    description={
                      "Understand the science and art of investing. Empower your decisions with affordable investing education. Crafted by investors for everyone.                      "
                    }
                    icon={hatIcon}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col mb-4 ">
                  <InfoCard
                    title={"Become a confident investor."}
                    description={
                      "  Build your investment knowledge from the ground up. Make confident investment decisions that fuel your returns. No more guesswork. Whether you’re a starter or a pro."
                    }
                    icon={moneyGroupIcon}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col mb-4 ">
                  <InfoCard
                    title={"Simple. Engaging. Actionable."}
                    description={
                      "Explore a comprehensive range of financial topics. Learn at your own pace and on the go. Powerful storytelling in premium video content. Easy to understand. Inspiring to watch."
                    }
                    icon={rocketIcon}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 d-flex justify-content-center align-items-center ">
            <img src={phoneAsset} loading='lazy' alt="mobilePhone" className="img-fluid" />
          </div>

          <div className="row mt-5">
            <div className="col  showOnSmall small-infoCard-container boxShadow">
              <ControlledCarousel content={carouselContent} variant={"dark"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skew1;
