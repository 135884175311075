import React from "react";

const OwSkewContainer = ({ backGround, children }) => {
  return (
    <div className={`owSkew ${backGround}`}>
      <div className="owSkew-content ">
        <div className="container">{children}</div>
      </div>
    </div>
  );
};

export default OwSkewContainer;
