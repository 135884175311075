import React from "react";

import stars from "../../resources/img/stars.png";
import nextArrow from "../../resources/img/nextArrow.svg";
import backgBtn from "../../resources/img/backBtn.svg";

const ReviewCard = ({
  name,
  title,
  avatar,
  description,
  position,
  increment,
  decrement,
  counter,
}) => {
  return (
    <div
      className={
        `container d-flex justify-content-between flex-column rc-card p-4 pos${position} rc-bg${position} ` +
        (position === 0 ? "position-relative" : "") +
        (position === counter - 1 ? " rc-bg-main" : "")
      }
    >
      <div className="rc-container">
        <div className="rc-header">
          <div className="row rc-title-row">
            <div className="col-1 d-flex justify-content-center align-items-center position-relative p-2">
              <img src={avatar} alt="" className=" rc-avatar" />
            </div>
            <div className="col d-flex flex-column justify-content-center">
              <h2 className="rc-title">{name}</h2>
              <h2 className="rc-subtitle">{title}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <img className="rc-stars" src={stars} alt="Star" />
            </div>
          </div>
        </div>
        <div className="row mt-2 mb-2 rc-description-container">
          <p className="rc-description">{description}</p>
        </div>

        <div className="row d-flex justify-content-between">
          <div className="col">
            {position !== 2 && (
              <button className="rc-backBtn" onClick={increment}>
                <img src={backgBtn} alt="back" />
              </button>
            )}
          </div>
          <div className="col d-flex justify-content-end">
            {position !== 0 && (
              <button className="rc-nextBtn" onClick={decrement}>
                Next <img src={nextArrow} alt="" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
