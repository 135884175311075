import React from "react";
import OurWhyBigCard from "./Cards/OurWhyBigCard";
import OurWhyCard from "./Cards/OurWhyCard";
import grayLine from "../resources/img/grayLine.svg";
import gradientLine from "../resources/img/gradientLineNoGlow.svg";
import img1 from "../resources/img/owImg1.webp";
import img2 from "../resources/img/owImg2.webp";
import img3 from "../resources/img/owImg3.webp";
import assetWeb from "../resources/img/asset-website.webp";
import assetMobile from "../resources/img/asset-mobile.webp";
import ControlledCarousel from "./ControlledCarousel";
import SubscribeToOurNewsletter from "./SubscribeToOurNewsletter";
import ActionsButtons from './ActionButtons';

const LongBoard = () => {
  const carouselContent = [
    <div className="col">
      <OurWhyCard
        description={
          "Built on a goal-based framework, we craft investing education that matters to you. We continuously monitor the latest trends to deliver relevant content."
        }
        img={img1}
      />
    </div>,
    <div className="col">
      <OurWhyCard
        description={
          "We screen financial markets for trending themes and under-the-radar opportunities. Our expert team develops unbiased investing insights. Assessed objectively. Delivered with clarity."
        }
        img={img2}
      />
    </div>,
    <div className="col">
      <OurWhyCard
        description={
          "Our diverse team of creative storytellers bring our vibrant content to life. Designed for audiences across generations. Straight from the Beurzbyte Studio to our mobile app."
        }
        img={img3}
      />
    </div>,
  ];
  return (
    <div className="longBoardBg padding10vhtop padding10vhbottom position-relative ">
      {/* <div className="longBoardGlow  "></div> */}
      <div className="container mt-5">
        <OurWhyBigCard />
        <div className="showOnSmall mt-5">
          <div className="row d-flex justify-content-center p-4 ">
            <ControlledCarousel content={carouselContent} variant="dark" />
          </div>
        </div>

        <div className="row experience-investment d-flex justify-content-center">
          <div className="col-lg-5 col-10 mt-5">
            <h1 className="bigCardTitle">
              Experience investment content like never before
            </h1>
          </div>
        </div>
        <div className="showOnBig">
          <div className="row d-flex justify-content-center mt-3">
            <div className="col-3 d-flex justify-content-center img-container">
              <img loading='lazy' src={gradientLine} alt="grayLine" className="img-fluid" />
            </div>
          </div>
        </div>
        <div className="row showOnSmall mt-3">
          <div className="col d-flex justify-content-center ">
            <img loading='lazy' src={grayLine} alt="grayLine" />
          </div>
        </div>
        <div className="row d-flex justify-content-center mt-3">
          <div className="col-lg-6">
            <h2 className="bigCardDescription">
              We transform actionable investing education and unbiased investing
              insights into powerful storytelling. Easy to understand. Inspiring
              to watch.
            </h2>
          </div>
        </div>
      </div>
      <div className=" showOnSmall ">
        <div className="longBoardAsset">
          <div className="">
            <img loading='lazy' src={assetMobile} alt="asset" className="w-100" />
          </div>
        </div>
      </div>
      <div className="showOnBig mt-5">
        <div className=" longBoardAsset ">
          <div className="d-flex justify-content-center ">
            <img loading='lazy' src={assetWeb} alt="asset" className="w-100" />
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='row d-flex justify-content-center'>
          <div className='col-lg-5 mt-4 d-flex justify-content-center'>
            <ActionsButtons />
          </div>
        </div>
      </div>


      <div className="container p-3" id="subscribe-card">
        <div className=" row d-flex align-items-center justify-content-center mt-5">
          <div className="col">
            <SubscribeToOurNewsletter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LongBoard;
