import React from "react";
import horizontalCash from "../../resources/img/horizontalCashGroup.png";
import verticalCash from "../../resources/img/verticalCashGroup.png";
import decore1 from "../../resources/img/workDecore1.svg"
const Fold4 = () => {
  return (
    <div className="z-index3">
      <div className="work-fold4-bg padding10vhtop padding10vhbottom position-relative z-index1">
        <div className="container ">
          <div className="work-decore">
            <img src={decore1} alt="" />
          </div>
          <div className="row d-flex flex-column justify-content-center gap-3 mt-5 pt-lg-5">
            <div className="col d-flex justify-content-center mt-lg-5">
              <h1 className="work-cash-title showOnBig">2022 WELLNESS BAROMETER SURVEY</h1>
              <h1 className="work-cash-title showOnSmall">change to 2022 WELLNESS <br></br> BAROMETER SURVEY</h1>
            </div>

          </div>
          <div className="row showOnBig  justify-content-center mt-5 pt-5 pb-5">
            <div className="col d-flex justify-content-center  img-container">
              <img src={horizontalCash} alt="" className="img-fluid" />
            </div>
          </div>
          <div className="row showOnSmall img-container mt-5">
            <div className="col d-flex justify-content-center  img-container">
              <img src={verticalCash} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Fold4;
