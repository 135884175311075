import React from "react";
import FaqQuestion from "./FaqQuestion";
import FaQDecore from "../../resources/img/faq-decoration.svg";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const FAQPAge = () => {
  const location = useLocation();

  React.useEffect(() => {
    // runs on location, i.e. route, change
    ReactGA.set({ page: location.pathname });
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  const generalQ = [
    {
      title: "What is Beurzbyte?",
      description:
        "Beurzbyte is a mobile app that empowers retail investors with actionable investing education and unbiased investing insights. Powered by best-in-class investment professionals. Our diverse team of creative storytellers bring our vibrant content to life. Designed for audiences across generations. Straight from the Beurzbyte Studio to our mobile app.",
    },
    {
      title: "Who is Beurzbyte for?",
      description:
        "At Beurzbyte, we believe everyone is an investor. Including you. We’re on a mission to make financial education and investment insights accessible to all. Grow your confidence. Make smarter financial decisions. Discover the investor in you.",
    },
    {
      title: "What is Beurzbyte Learn?",
      description:
        "With Beurzbyte Learn, you can build your investment knowledge from the ground up. Make confident investment decisions that fuel your returns. No more guesswork. Whether you’re a starter or a pro. You can explore a comprehensive range of financial topics. Learn at your own pace and on the go. Powerful storytelling in premium video content.<br></br>At Beurzbyte, we believe everyone is an investor. Including you. That’s why we are offering Beurzbyte Learn at zero cost for a limited time.",
    },
    {
      title: "What is Beurzbyte Discover?",
      description:
        "With Beurzbyte Discover, you can tap into unbiased and independent investing insights. From stocks to crypto, we screen financial markets for trending themes and under-the-radar opportunities. Our expert team develops unbiased investing insights. Assessed objectively. Delivered with clarity. We transform endless hours of research into digestible content. Easy to understand. Inspiring to watch.",
    },
    {
      title: "Does Beurzbyte provide financial advice?",
      description:
        "Beurzbyte is for informational and educational purposes only and does not constitute financial, investment, or tax advice. Beurzbyte is not regulated by the Financial Conduct Authority and does not offer or solicit to buy or sell any security. Beurzbyte is not a broker, accountant, portfolio manager, or financial advisor. We disclaim all liability and responsibility arising from any reliance placed on the information on our website, mobile app, or any other material produced by Beurzbyte by any user, or by anyone who may be informed of any of its contents.<br></br>You understand and agree that the information provided on our website, platform, mobile app, or any other material produced by Beurzbyte does not constitute any form of advice, recommendation, endorsement or arrangement. Past performance does not guarantee future results or returns. As always, with investing, capital is at risk, including the possible loss of capital.",
    },
    {
      title: "How can I get in touch?",
      description:
        "You can get in touch via our support chat function on our website at <a class='faq-link' href='www.beurzbyte.io'>www.beurzbyte.io</a> or reach us via email at <a class='faq-link' href='mailto:info@beurzbyte.io'>info@beurzbyte.io</a>.",
    },
    {
      title: "Where is Beurzbyte based?",
      description:
        "We are headquartered in London, United Kingdom. Feel free to pay us a visit, but give us a heads up first at <a class='faq-link' href='mailto:info@beurzbyte.io'>info@beurzbyte.io</a>.",
    },
    {
      title: "Where can I access the Beurzbyte Terms and Conditions?",
      description:
        "You can access our “Terms and Conditions” on our website at <a class='faq-link' href='www.beurzbyte.io'>www.beurzbyte.io</a> or <a class='faq-link' href='/terms-and-conditions'>directly using this link</a>.",
    },
    {
      title: "Where can I access the Beurzbyte Privacy Policy?",
      description:
        "You can access our “Terms and Conditions” on our website at <a class='faq-link' href='www.beurzbyte.io'>www.beurzbyte.io</a> or <a class='faq-link' target='_blank' href='https://app.privasee.io/privacy-center/64088427e45ace00134369ce'>directly using this link</a>.",
    },
    {
      title: "Where can I access the Beurzbyte Cookie Policy?",
      description:
        "You can access our “Terms and Conditions” on our website at <a class='faq-link' href='www.beurzbyte.io'>www.beurzbyte.io</a> or <a class='faq-link' href='/cookie-policy'>directly using this link</a>.",
    },
  ];
  const appQuestion = [
    {
      title: "How can I download the Beurzbyte App?",
      description:
        "The Beurzbyte app is currently available for download from the Apple App Store. You can download it <a class='faq-link' href=https://apps.apple.com/uk/app/id6443670820'>here.</a>",
    },
    {
      title: "Is the Beurzbyte App available on Android?",
      description:
        "We are working hard behind the scenes to make our application available on Android as well. Register your interest here and join the Android waitlist: <a class='faq-link' href='www.beurzbyte.io/android'>www.beurzbyte.io/android</a>.",
    },
    {
      title: "How does the search function on the Beurzbyte App work?",
      description:
        "Within the Beurzbyte app, we have a search function that allows you to search across Beurzbyte Learn and Beurzbyte Discover by learning modules and name or sector of investing insights and opportunities. Within the search function, you can also search by means of voice control by single-tapping the microphone button.<br></br>The search function will give you the best results for your search. If the search is not yet available, we’ll do our best to add it to the platform as soon as possible. If you want to see a specific asset or learning module on the platform, you can always contact us via our support chat function on our website at <a class='faq-link' href='www.beurzbyte.io'>www.beurzbyte.io</a> or send us an email at <a class='faq-link' href='mailto:info@beurzbyte.io'>info@beurzbyte.io</a>.",
    },
    {
      title: "Who creates the content on the Beurzbyte App?",
      description:
        "All content on the Beurzbyte app is carefully crafted by our in-house team of investment professionals and brought to life by our diverse team of creative storytellers.",
    },
  ];
  const pricingQ = [
    {
      title: "How do I purchase a Beurzbyte subscription?",
      description:
        "Download the Beurzbyte App for free in Apple’s App Store. Once downloaded, you can get started with Beurzbyte Learn for free or purchase a Beurzbyte Premium subscription.",
    },
    {
      title: "How much does Beurzbyte Premium cost?",
      description:
        "Beurzbyte premium costs £29.99 per month. We currently have a limited time offer at £99.99 per year if you choose the Beurzbyte premium annual subscription. To find the subscription price in your local currency, please visit the Apple App Store.",
    },
    {
      title: "What do I get with Beurzbyte Learn?",
      description:
        "With Beurzbyte Learn, you get access to the learn section of the Beurzbyte app with the following benefits:<ul class='faq-d-list'><li class='faq-d-list-item'>Build your investment knowledge from the ground up with affordable investing education.</li> <li class='faq-d-list-item'>Full access to Beurzbyte Learn.</li> <li class='faq-d-list-item'>Get your first 3 videos in Beurzbyte Discover for free.</li></ul>",
    },
    {
      title: "What do I get with my Beurzbyte Premium subscription?",
      description:
        "With Beurzbyte premium, you get full access to the entire Beurzbyte app with the following benefits: <ul class='faq-d-list'><li class='faq-d-list-item'>Full access to Beurzbyte Learn.</li><li class='faq-d-list-item'>Full access to Beurzbyte Discover.</li><li class='faq-d-list-item'>Discover opportunities, stocks, crypto, insights, and ETFs</li><li class='faq-d-list-item'>Tap into unbiased and independent research.</li><li class='faq-d-list-item'>Tailor your Beurzbyte experience to your preferences.</li><li class='faq-d-list-item'>Explore what’s trending in the investment world.</li></ul>",
    },
    {
      title: "Is there a free trial period?",
      description:
        "There is no free trial period. However, Beurzbyte Learn is currently free for a limited time. You can also earn free access to Beurzbyte Premium by inviting your friends. Use the “Free Access” button at the top right corner on the app. For every successful invite, you get 3 days free access to Beurzbyte Premium. Terms and Conditions apply.",
    },
    {
      title: "How does the Beurzbyte referral system work?",
      description:
        "For every person you invite to Beurzbyte and who accepts your invitation, you get 3 days of free access to Beurzbyte Premium. Use the “Free Access” button at the top right corner on the app. Then select “Invite your friends”. This will generate a unique referral link which you can share with your friends. For every successful invite, you get 3 days free access to Beurzbyte Premium. Terms and Conditions apply.",
    },
    {
      title:
        "How can I see how many days of free access I have left using the referral system?",
      description:
        "You can easily view how many free days of Beurzbyte Premium (full access) you have left on the app. Go to “Profile” via the menu bar. At the top, under “Invite your friends”, you’ll be able to see how many days you have left.",
    },
    {
      title: "How can I check the status of my Beurzbyte subscription?",
      description:
        "You can easily view the status of your current subscription on the app. Go to “Profile” via the menu bar. Then select “Subscription”.",
    },
    {
      title:
        "I have paid for Beurzbyte Premium, but cannot access Beurzbyte Premium?",
      description:
        "Make sure you have logged in with the correct email address first. Second, ensure you are using the most recent version of the Beurzbyte app. Visit the Apple App store to see if you have the most up-to-date version installed.<br></br>If you’ve bought your Beurzbyte Premium subscription via the Apple App Store, you can always restore your purchase via the mobile app. Go to “Profile” via the menu bar. Then select “Subscription”. Select “Restore Purchase” at the bottom of the page. This will restore your purchase on our app and refresh the mobile app for you.<br></br>For any questions, please contact us via our chat support on our website at <a class='faq-link' href='www.beurzbyte.io'>www.beurzbyte.io</a> or via email at <a class='faq-link' href='mailto:info@beurzbyte.io'>info@beurzbyte.io</a>.",
    },
    {
      title: "Can I get a refund on my Beurzbyte subscription?",
      description:
        "If you purchased your subscription directly in-app via the Apple App Store, please use <a class='faq-link' href='https://support.apple.com/en-gb/HT204084'>this link to request a refund</a>. Premium Subscriptions are subject to the refund policies of the Apple App Store. This means Beurzbyte cannot grant refunds, and you will have to contact your App Store support if you wish to pursue any type of refund.<br></br>We do not provide refunds or credits for any partially used Premium Subscription purchased through Beurzbyte. If you cancel the auto-renew on your subscription, you will still be able to use your Premium subscription until the end of your billing cycle. For any questions, please contact us via our chat support on our website at <a class='faq-link' href='www.beurzbyte.io'>www.beurzbyte.io</a> or via email at <a class='faq-link' href='mailto:info@beurzbyte.io'>info@beurzbyte.io</a>.",
    },
    {
      title: "How do I cancel my subscription?",
      description:
        "You can easily cancel your subscription at any time on the app. Go to “Profile” via the menu bar. Then select “Subscription”. Alternatively, if you paid for your subscription through the Apple Store, you can cancel <a class='faq-link' href='https://support.apple.com/en-gb/HT202039'>using this link.</a>",
    },
    {
      title: "How can I delete my account?",
      description:
        "You can delete your account on the mobile app. Go to “Profile” via the menu bar. Then select “Delete account”.",
    },
    {
      title: "How can I unsubscribe from Beurzbyte 360 Newsletters?",
      description:
        "At the bottom of each newsletter, you can select “Update your preferences” or “Unsubscribe”.",
    },
  ];
  return (
    <div>
      <div className="faqBg position-relative padding10vhbottom padding10vhtop overflow-hidden">
        <div className="faq-decore img-container showOnBig">
          <img src={FaQDecore} alt="decore " className="img-fluid" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col">
              <h1 className="press-small-title">BEURZBYTE FAQ</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <h1 className="bb-title color-white">
                Welcome to our Frequently Asked Questions
              </h1>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col">
              <p className="color-white"> We are here to help. </p>
            </div>
          </div>
        </div>
      </div>
      <div className="ow-fold2">
        <div className="container  padding10vhbottom">
          <div className="row ">
            <div className="col mt-100 ">
              <h1 className="faq-category">General</h1>
              {generalQ.map((item, index) => (
                <FaqQuestion
                  key={index}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </div>
          </div>

          <div className="row mt-100">
            <div className="col">
              <h1 className="faq-category">THE BEURZBYTE APP</h1>
              {appQuestion.map((item, index) => (
                <FaqQuestion
                  key={index}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </div>
          </div>

          <div className="row mt-100">
            <div className="col">
              <h1 className="faq-category">Pricing and subscriptions</h1>
              {pricingQ.map((item, index) => (
                <FaqQuestion
                  key={index}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </div>
          </div>

          <div className="row mt-100 faq-bottom-container">
            <div className="col d-flex justify-content-center">
              <div className="faq-gradient-bg">
                <div className="faq-gradient-bg2">
                  <div className="col faq-bottom-container-content d-flex flex-column justify-content-center align-items-lg-center">
                    <h1 className="faq-bottom-title">
                      If you have any other questions or you couldn’t find what
                      you were looking for in our FAQ, you can contact us via
                      email.
                    </h1>
                    <a
                      className="faq-questionBtn mt-4 d-flex justify-content-center"
                      href="mailto:info@beurzbyte.io"
                    >
                      Ask your question
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQPAge;
