import React, { useEffect, useState } from "react";
import line from "../resources/img/decore3.svg";
import InfoCard from "./Cards/InfoCard";
import ideaIcon from "../resources/img/ideaIcon.svg";
import statisticIcon from "../resources/img/statistics.svg";
import userIcon from "../resources/img/user.svg";
import phoneAsset from "../resources/img/PhoneFrame3.webp";
import ControlledCarousel from "./ControlledCarousel";
import SmallInfoCard from "./Cards/SmallInfoCard";

const Skew3 = () => {
  const carouselContent = [
    <SmallInfoCard
      title={"Unbiased investing insights."}
      description={
        "Discover opportunities. Tap into unbiased and independent research. All in premium video content. Powered by our dedicated in-house team of investment professionals."
      }
      icon={ideaIcon}
    />,
    <SmallInfoCard
      title={"Become a smarter investor."}
      description={
        "Know your investments. Inside and out. Make smarter investment decisions. Gain a competitive advantage and filter out the noise."
      }
      icon={statisticIcon}
    />,
    <SmallInfoCard
      title={"Tailored. Transparent. Time-efficient."}
      description={
        "Tailor your Beurzbyte experience to your preferences. Explore what’s trending in the investment world. Generate ideas on the go. Transparent and to the point."
      }
      icon={userIcon}
    />,
  ];
  const titles = ["Opportunities", "Stocks", "Indexes", "ETFs", "Crypto"];
  const [count, setCount] = useState(0);

  const changeTitle = () => { };
  useEffect(() => {
    const timer = setTimeout(() => {
      if (count !== titles.length - 1) {
        setCount(count + 1);
      } else {
        setCount(0);
      }
    }, 1500);
  }, [count]);

  return (
    <div className="lightBg padding10vhbottom padding10vhtop" id="discover">
      <div className="container">
        <div className="row ">
          <div className="col d-flex">
            <h1 className="skewTitle ">
              Discover <span className="showOnSmall"></span>{" "}
              <span className="purple2"> {titles[count]}</span>
            </h1>
          </div>
        </div>

        <div className="row">
          <div className="col-1">
            <img src={line} alt="line"></img>
          </div>
        </div>
        <div className="row">
          <div className="col  mt-2">
            <h2 className="skewDescription">
              Start discovering opportunities and insights with
              <span className="bld"> Beurzbyte Premium</span>
            </h2>
          </div>
        </div>

        <div className="row mt-5 d-flex justify-content-center">
          <div className="col-lg-6 ">
            <div className="showOnBig">
              <div className="row ">
                <div className="col mb-4 ">
                  <InfoCard
                    title={"Unbiased investing insights."}
                    description={
                      "Discover opportunities. Tap into unbiased and independent research. All in premium video content. Powered by our dedicated in-house team of investment professionals."
                    }
                    icon={ideaIcon}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col mb-4 ">
                  <InfoCard
                    title={"Become a smarter investor."}
                    description={
                      "Know your investments. Inside and out. Make smarter investment decisions. Gain a competitive advantage and filter out the noise."
                    }
                    icon={statisticIcon}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col mb-4 ">
                  <InfoCard
                    title={"Tailored. Transparent. Time-efficient."}
                    description={
                      " Tailor your Beurzbyte experience to your preferences. Explore what’s trending in the investment world. Generate ideas on the go. Transparent and to the point.    "
                    }
                    icon={userIcon}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 d-flex justify-content-center align-items-center ">
            <img src={phoneAsset}  loading='lazy' alt="mobilePhone" className="img-fluid " />
          </div>

          <div className="row mt-5 mb-5">
            <div className="col showOnSmall small-infoCard-container boxShadow ">
              <ControlledCarousel content={carouselContent} variant="dark" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skew3;
