import React from "react";

const SkewContainer = ({ children }) => {
  return (
    <div className="skew lightBg">
      <div className="skew-rev lightBg ">
        <div className="skew-cont lightBg ">
          <div className="container">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default SkewContainer;
