import React, { useState } from "react";
import $ from "jquery";

const Fold1 = () => {
  const bookACall = () => {
    let url =
      "https://calendly.com/pedram-parhizkari/?email=" +
      $("#call-email-address").val();
    var win = window.open(url, "_blank");
    win.focus();

    $("#call-email-address").val("");
  };

  const [input, setInput] = useState("");
  return (
    <div className="work-fold1 padding10vhtop padding10vhbottom">
      <div className="container ">
        <div className="row ">
          <div className="col-lg-6 d-flex flex-column justify-content-center">
            <h2 className="press-small-title">BEURZBYTE AT WORK</h2>
            <h1 className="bb-title color-white">
              Empower your employees with financial education and investment
              insights
            </h1>
            <p className="col-lg-9 color-white mt-lg-4 mt-3 work-f1-subtitle">
              The only guide your employees need to empower their investment
              decisions.
            </p>
            <p className="color-white mt-lg-1 work-f1-subtitle2">
              Let your employees decide.
            </p>
            <div className="col d-flex w-100 mt-lg-5 mt-4 mt-0 position-relative">
              <input
                id="call-email-address"
                placeholder="Enter your email address"
                className="w-100 subscribe-input"
                value={input}
                onChange={(e) => setInput(e.target.value)}
              ></input>
              <button className="subscribe-button" onClick={bookACall}>
                Book a call
              </button>
            </div>
            <div className="col mt-4">
              <p className="subscriptionP">
                Group rates are available for volume based orders.
              </p>
              <p className="contact-sales-link">
                {" "}
                <a href="mailto:info@beurzbyte.io" className="">
                  Contact Sales
                </a>{" "}
                to learn more.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fold1;
