import React from "react";
import ceoImage from "../../resources/img/ceoImg.png";
import decore from "../../resources/img/ow-decore.png";
import decore2 from "../../resources/img/ow-decoreTop.png";
import linkedInBordered from "../../resources/img/LinkedInBordered.svg";
import owDecor1 from "../../resources/img/ow-glow1.svg"
import owDecor2 from "../../resources/img/ow-glow2.svg"
import owDecor3 from "../../resources/img/ow-glow3.svg"
const Fold3 = () => {
  return (
    <div className="ceoBg paddingCeo position-relative  z-index1">
      <div className="img-container owDecore1 z-index3">
        <img src={decore} alt="" className="img-fluid" />
      </div>
      <div className="img-container owDecore2 z-index3">
        <img src={decore2} alt="" className="img-fluid" />
      </div>

      <div className="glowContainer showOnBig">
        <div className="glowFold2 z-index2"></div>
        <img src={owDecor1} className="owDecor1" alt=""></img>
        <img src={owDecor2} className="owDecor2" alt=""></img>
        <img src={owDecor3} className="owDecor3" alt=""></img>
      </div>

      <div className="container  d-flex flex-column ">
        <div className="z-index3">
          <div className="row d-flex  justify-content-center  z-index3">
            <div className="col-lg-8 d-flex  flex-column justify-content-center ">
              <h1 className="bb-title color-white">Our Story</h1>
              <p className="color-white header-description-container ">
                The investment landscape will never be the same again. People from
                all walks of life around the world are getting access to financial
                markets - and rightly so! May that be traditional investments such
                as stocks and bonds or alternative assets such as crypto, real
                estate, and hedge funds.
              </p>
              <p className="color-white header-description-container ">
                With great power comes great responsibility. Fuelled by a passion
                for investing, we’re on a mission to bridge the gap between Wall
                Street and Main Street when it comes to investing education and
                financial information. Our vision is to see a world where everyone
                makes well-informed, confident, and smarter investment decisions.
              </p>
              <p className="color-white header-description-container ">
                Our story began in the picturesque city of Bruges in Belgium where
                we spent our childhood. That’s why we named the company Beurzbyte,
                after ‘Ter Beurze’, the world’s first stock exchange, with a
                digital spin to it.
              </p>
              <p className="color-white header-description-container ">
                We’re on a mission to empower investors worldwide. Our
                consumer-centric mindset and our values-based principles will lead
                us there.
              </p>
            </div>

          </div>
          <div className="row d-flex justify-content-center mt-4">
            <div className="col-lg-8 d-flex justify-content-center img-container ">
              <img
                src={ceoImage}
                alt="ceoImg"
                className="img-fluid header-description-container"
              />
            </div>
          </div>
          <div className="row d-flex justify-content-center ">
            <div className="col-lg-4 col-6 d-flex flex-column justify-content-center align-items-center  ">
              <h1 className="ceoName header-description-container">
                Niels Soete
              </h1>
              <p className="ceo-function mt-lg-2">CO-FOUNDER & CO-CEO</p>
              <a href="https://www.linkedin.com/in/niels-soete-089a34157/">
                <img
                  src={linkedInBordered}
                  alt="linkedIn"
                  className=" linkedIn-size mt-lg-2"
                />
              </a>
            </div>
            <div className="col-lg-4 col-6  d-flex flex-column justify-content-center align-items-center">
              <h1 className="ceoName header-description-container">
                Pedram Parhizkari, CAIA
              </h1>
              <p className="ceo-function mt-lg-2">CO-FOUNDER & CO-CEO</p>
              <a href="https://www.linkedin.com/in/pedramparhizkari/">
                <img
                  src={linkedInBordered}
                  alt="linkedIn"
                  className="linkedIn-size mt-lg-2"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="showOnSmall mt-40"></div>
      </div>
    </div>
  );
};

export default Fold3;
