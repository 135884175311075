import instagramlogo from "../resources/img/Instagram.svg";
import facebooklogo from "../resources/img/Facebook.svg";
import tiktoklogo from "../resources/img/TikTok.svg";
import linkedinlogo from "../resources/img/LinkedIn.svg";
import youtubelogo from "../resources/img/YouTube.svg";
import NavigationBar from "./NavigationBar";
import $ from "jquery";
import { children } from './MenuItems';
import { useState } from 'react';

function MobileMenuButton({ title, href, children }) {

  const [isShown, setIsShown] = useState(false);

  const onClick = () => {
    // Show hide menu container
    $("#mobile-menu-container").toggle();
    $("#desktop-navigation-bar").toggle();
    $("#navigation-bar-container-outter-view").toggleClass("full-height");
    setIsShown(false);
  };

  return (
    <div
      id="mobile-button-action-container"
      className="fill-height"
    >
      {children && children.length > 0 ?
        (
          <div className='mobile-button-action-expand'>
            <div onClick={() => setIsShown(!isShown)} className="mobile-button-action-text children">
              {title}
            </div>
            <ul className={isShown ? "mobile-button-action-list active": "mobile-button-action-list"}>
              {children && children.map((element, index) => {
                return (
                  <li key={index}>
                    <a className="mobile-button-action-list-item" onClick={onClick} href={element.href}>
                      <span className="menu-title">{element.title}</span>
                      <span className="menu-description">
                        {element.description}
                      </span>
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        )
        : (
          <a className={children && children.length > 0 ? "mobile-button-action-text children" : "mobile-button-action-text"} href={href}>
            {title}
          </a>
        )}
    </div>
  );
}

function Separator() {
  return (
    <div className="separator-container">
      <div className="separator"></div>
    </div>
  );
}

function SocialIcon({ img, href }) {
  return (
    <div className="social-icon-container">
      <a className="social-icon-href" href={href}>
        <img src={img} className="social-icon" alt="social-icon" />
      </a>
    </div>
  );
}

export default function MobileMenu() {
  return (
    <div id="mobile-menu-container" className="fill-height">
      <div className="mobile-menu-bar-container">
        <NavigationBar />
      </div>
      <div className="mobile-menu-links-container">
        <div className="container pl-20">
          {/* Uncomment this after they have been implemented */}
          <div className="row mb-15">
            <div className="col">
              <MobileMenuButton title="Resources" children={children} href="#resources" />
            </div>
          </div>
          <div className="row mb-15">
            <div className="col">
              <MobileMenuButton title="At Work" href="/work" />
            </div>
          </div>
          <div className="row mb-15">
            <div className="col">
              <MobileMenuButton title="Our Why" href="/ourwhy" />
            </div>
          </div>
        </div>
        <Separator />
        <div className="container pl-20">
          <div className="row">
            <div className="col">
              <SocialIcon
                img={instagramlogo}
                href="https://www.instagram.com/beurzbyte/"
              />
            </div>
            <div className="col">
              <SocialIcon
                img={tiktoklogo}
                href="https://www.tiktok.com/@beurzbyte"
              />
            </div>
            <div className="col">
              <SocialIcon
                img={youtubelogo}
                href="https://www.youtube.com/channel/UCB6Qk70rsJ0SWQ_UC-Xnyxw"
              />
            </div>
            <div className="col">
              <SocialIcon
                img={linkedinlogo}
                href="https://www.linkedin.com/company/beurzbyte/"
              />
            </div>
            <div className="col">
              <SocialIcon
                img={facebooklogo}
                href="https://www.facebook.com/beurzbyte"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
