import React from "react";

const OurWhyCard = ({ img, description }) => {
  return (
    <div className="owCard-container">
      <div className="owCard-container-gradient d-flex flex-column ">
        <div className="owCard-container owCard-minusMargin">
          <div className="owCard-container-gradient">
            <img src={img} loading='lazy' className="owCard-img imgborder" alt="img"></img>
          </div>
        </div>
        <div className="row ow-description-container">
          <div className="col">
            <p className="owCard-description p-3 mb-auto">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurWhyCard;
