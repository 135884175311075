import Navigation from "./components/Navigation";
import Footer from "./components/Footer";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./components/Home/HomePage";
import OurWhyPage from "./components/OurWhy/OurWhyPage";
import AtWorkPage from "./components/At Work/AtWorkPage";
import Presspage from "./components/Press/Presspage";
import FAQPAge from "./components/FAQ/FAQPAge";
import TermsAndConditions from "./components/Terms/TermsAndConditions";
import React from "react";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <div id="app-content">
          <Navigation />
          <Routes>
            <Route index element={<HomePage />} />
            <Route path="/" element={<HomePage />} />
            <Route path="/ourwhy" element={<OurWhyPage />} />
            <Route path="/work" element={<AtWorkPage />} />
            <Route path="/press" element={<Presspage />} />
            <Route path="/faq" element={<FAQPAge />} />
            <Route path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
          </Routes>
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
