import React from "react";
import SubscribeToOurNewsletter from "../SubscribeToOurNewsletter";
import ArticleContainer from "./ArticleContainer";
import techeu from "../../resources/img/press/techEu.png";
import finextra from "../../resources/img/press/finExtra.png";
import fintech from "../../resources/img/press/finTech.png";
import tfn from "../../resources/img/press/tfn.png";
import er from "../../resources/img/press/edTech.png";
import detjid from "../../resources/img/press/deTjid.png";
import businessAm from "../../resources/img/press/businessAm.png";
import hln from "../../resources/img/press/hln.png";
import kw from "../../resources/img/press/kw.png";

const Articles = () => {
  const articles = [
    {
      title: techeu,
      date: "19 January 2023",
      subtitle:
        "London-based financial literacy platform Beurzbyte raises £2.2 million",
      description:
        "London-based retail investment education platform Beurzbyte is announcing its pre-seed fundraise, tying down £2.2 million from angel investors to deliver on its video-first  educational and investment marketplace. Beurzbyte's software platform is aimed at customers looking to get a handle on earnings from financial trades.",
      page: "https://tech.eu/2023/01/19/london-based-financial-literacy-platform/",
    },
    {
      title: finextra,
      date: "18 January 2023",
      subtitle:
        "Video-first financial literacy platform Beurzbyte raises £2.2m",
      description:
        "The investment landscape will never be the same again. People from all walks of life around the world are getting access to financial markets - and rightly so! May that be traditional investments such as stocks and bonds or alternative assets such as crypto, collectibles, art, real estate, and hedge funds.        ",
      page: "https://www.finextra.com/pressarticle/95474/video-first-financial-literacy-platform-beurzbyte-raises-22m",
    },
    {
      title: detjid,
      date: "19 January 2023",
      subtitle: "West-Vlaamse ondernemers leren generatie Z beleggen",
      description:
        "Met hun app voor financiële educatie BeurzByte willen de West-Vlaamse City-Belgen Niels Soete en Pedram Parhizkari beleggen begrijpelijk maken voor het grote publiek. 'Mensen weten nu zelfs vaak niet hoe ze een account bij een broker moeten aanmaken.'        ",
      page: "https://www.tijd.be/content/tijd/nl/mme-articles/10/44/24/27/10442427",
    },
    {
      title: tfn,
      date: "20 January 2023",
      subtitle:
        "Two Belgian nationals founded financial education firm snaps £2.2M",
      description:
        "At present, retail investors have access to a wide range of asset classes. However, with too many options and overwhelming amounts of information, one can get easily confused and unsure where to turn. As a result, two Belgian nationals wanted to help retail investors understand their investments and make well-informed decisions through their startup — Beurzbyte.",
      page: "https://techfundingnews.com/two-belgian-nationals-founded-financial-education-firm-snaps-2-2m-to-bridge-wall-street-and-main-street/",
    },
    {
      title: er,
      date: "20 January 2023",
      subtitle: "London-based Financial Education Firm Beurzbyte Raises $2.7M",
      description:
        "London-based financial education platform, has raised $2.7 million in a pre-seed funding round from undisclosed angel investors. Founded by Pedram Parhizkari and Niels Soete, Beurzbyte is on a mission to make financial education and investment insights accessible to all, with a vision to see a world where everyone makes well-informed investment decisions.",
      page: "https://www.edtechreview.in/news/london-based-financial-education-firm-beurzbyte-raises-2-7m/",
    },
    {
      title: fintech,
      date: "24 January 2023",
      description:
        "Beurzbyte aims to solve the problem of financial education and access to affordable, reliable, and simple investment tools for retail investors. Beurzbyte is a mobile app that helps retail investors learn the ins-and-outs of investing and discover investment opportunities through video content.        ",
      subtitle: "Video-first financial literacy  firm Beurzbyte lands £2.2m",
      page: "https://fintech.global/2023/01/19/video-first-financial-literacy-firm-beurzbyte-lands-2-2m/",
    },
    {
      title: hln,
      date: "8 February 2023",
      description:
        "Hun applicatie ‘Beurzbyte’ wordt pas volgende maandag wereldwijd gelanceerd, maar nu al staat de start-up van de twee West-Vlamingen Niels Soete (25) en Pedram Parhizkari (32) tussen de 1.000 meest trending start-upbedrijven van het moment. Het jonge bedrijf zegt de kloof tussen de gewone man en de financiële markten te willen verkleinen.  ",
      subtitle:
        "Jonge West-Vlamingen zijn met beursapp 1 van 1.000 meest trending bedrijven",
      page: "https://www.hln.be/brugge/twee-jonge-west-vlamingen-moeten-beursapp-nog-lanceren-maar-staan-nu-al-in-top-1-000-van-trending-start-up-bedrijven-we-willen-mensen-helpen-om-de-juiste-keuzes-te-maken~acf70c0e/?cb=e22bb4630c4e7622f029a9a4e8ff1c20&auth_rd=1",
    },
    {
      title: businessAm,
      date: "4 February 2023",
      description:
        "Beurzbyte haalt 2,2 miljoen pond financiering op. De Belgische start-up zegt de kloof tussen de gewone man en de financiële markten te willen verkleinen. Beleggen is verschoven van de elite naar iedereen met een internetverbinding. Beurzbyte zegt de lampendrager te willen zijn voor zij die overspoeld worden door informatie.",
      subtitle:
        "“Van Wall Street naar Main Street” Beurzbyte wil beleggen democratiseren",
      page: "https://businessam.be/beurzbyte-democratiseert-beleggen/",
    },
    {
      title: kw,
      date: "9 February 2023",
      description:
        "Beurzbyte, een start-up van twee jonge West-Vlamingen die vanuit de Londense City aan hun droom timmeren, heeft 2,5 miljoen euro opgehaald om hun financiële educatie-app verder uit te bouwen. Niels Soete en Pedram Parhizkari willen beleggen begrijpelijk maken voor een breed publiek. “We willen dé referentie worden.”",
      subtitle: "Ambitieuze West-Vlaamse start-up haalt 2,5 miljoen euro op",
      page: "https://kw.be/nieuws/economie/beurzbyte-helpt-je-vanuit-londen-beleggen-ambitieuze-west-vlaamse-start-up-haalt-25-miljoen-euro-op/",
    },
  ];
  return (
    <div className="ow-fold2">
      <div className="container padding10vhbottom padding10vhtop">
        <div className="row">
          {articles.map((item, index) => (
            <div className="col mt-4 d-flex justify-content-center" key={index}>
              <ArticleContainer
                title={item.title}
                date={item.date}
                subtitle={item.subtitle}
                description={item.description}
                page={item.page}
              />
            </div>
          ))}
        </div>
        <div className="row d-flex align-items-center justify-content-center mt-5">
          <div className="col">
            <SubscribeToOurNewsletter theme={"subscribe-pale-pink"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Articles;
