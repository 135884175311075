import React from "react";
import bCorpLogo from "../../resources/img/certified-b-corp-background.png";

const Fold1 = () => {
  return (
    <div className="ow-fold1  padding10vhtop padding10vhbottom">
      <img src={bCorpLogo} className="b-corp-logo-our-why" alt="b-corp-logo" />
      <div className="container ">
        <div className="col-lg-5 d-flex flex-column gap-4 our-why-main-header-container">
          <h1 className="ow-title">
            We believe <span className="line-decoration">everyone</span> is an
            investor.
            <br></br>
            <span className="ow-title-span"> Including you.</span>
          </h1>
          <p className="ow-description">
            When it comes to investing, we envision a world where everyone can
            make well-informed, confident, and smarter financial decisions. No
            matter who you are, no matter where you’re from, you deserve to be
            empowered with best-in-class investing education and actionable
            investing insights. That’s our mission.
          </p>
          <p className="ow-description">
            We all have our own personal journey in life. Making better
            financial decisions is an essential part of it. At Beurzbyte, we
            want to equip you with the right knowledge and tools to back your
            decisions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Fold1;
