import React from "react";

const UnlockBenefitCard = () => {
  const openCallSimple = () => {
    let url = "https://calendly.com/pedram-parhizkari/";
    var win = window.open(url, "_blank");
    win.focus();
  };

  return (
    <div className="work-benefit">
      <div className="work-benefit-left showOnBig ">
        <h1 className="work-b-left-title">Benefits</h1>
        <p className="mt-4 work-b-left-description">
          Unlock the full potential of your employees
        </p>
      </div>

      <div className="work-benefit-right">
        <div className="">
          <h1 className="work-benefit-title">Try Beurzbyte at work</h1>
          <p className="work-benetif-description mt-sm-4  mt-lg-3 ">
            The only guide your employees need to empower their investment
            decisions.{" "}
          </p>
        </div>

        <div className="work-benefit-bottom d-flex align-items-center justify-content-between">
          <h1 className="showOnBig ">Price upon request</h1>
          <button className="" onClick={openCallSimple}>
            Book a call
          </button>
        </div>
      </div>
    </div>
  );
};

export default UnlockBenefitCard;
