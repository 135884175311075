import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

function ControlledCarousel3({ content, variant, className }) {
    const [index, setIndex] = useState(0);
    const len = [1, 2, 3]

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const Condition = ({ index, items }) => {
        if (index + 2 < items.length) {
            return (
                <> {items[index]}
                    {items[index + 1]}
                    {items[index + 2]}
                </>
            )
        }
        else
            return;
    }
    return (
        <Carousel variant={variant} activeIndex={index} onSelect={handleSelect}>
            {len.map((item, index) => (
                <Carousel.Item key={index}>
                    <div className="col p-3 d-flex justify-content-center align-items-center mlminus ">
                        <Condition index={index} items={content} />
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    );
}
export default ControlledCarousel3;
