import NavigationBar from "./NavigationBar";
import MobileMenu from "./MobileMenu";

export default function Navigation() {
  return (
    <div id="navigation-bar-container-outter-view">
      <div id="desktop-navigation-bar">
        <NavigationBar />
      </div>
      <MobileMenu />
    </div>
  );
}
