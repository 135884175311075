import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

function ControlledCarousel({ content, variant, className }) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel variant={variant} activeIndex={index} onSelect={handleSelect}>
      {content.map((item, index) => (
        <Carousel.Item key={index}>
          <div className="col p-3 d-flex justify-content-center align-items-center mlminus ">

            {item}

          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
export default ControlledCarousel;
