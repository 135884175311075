import React from "react";

import stars from "../../resources/img/stars.png";


const ReviewCardWork = ({
    name,
    title,
    avatar,
    description,

}) => {
    return (
        <div
            className={
                `container work-rc-card rc-bg2 p-3`
            }
        >
            <div className="row d-flex align-items-center ">
                <div className="col-3">
                    <img src={avatar} alt=""></img>
                </div>
                <div className="col">
                    <h1 className="rc-title">{name}</h1>
                    <h1 className="rc-subtitle">{title}</h1>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <img className="rc-stars" src={stars} alt="Star" />
                </div>
            </div>
            <div className="row ">
                <div className="col">
                    <p className="work-rc-description">{description}</p>
                </div>
            </div>
        </div>
    );
};

export default ReviewCardWork;
