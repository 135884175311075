import { useState } from 'react';
import logoimg from "../resources/img/logo-bb.png";
import menuimg from "../resources/img/menu.svg";
import $ from "jquery";
import { children } from './MenuItems';



function NavBarButton({ title, href, children }) {
  const [isShown, setIsShown] = useState(false);

  return (
    <div className="nav-bar-button-container d-flex" onMouseEnter={() => setIsShown(true)}
    onMouseLeave={() => setIsShown(false)}>
      <a className="nav-bar-button" href={href}>
        {title}
      </a>
      {children && children?.length > 0 && isShown &&
          <div className='nav-bar-children'>
           <div className='nav-bar-children-inner'>
            <ul>
              {children && children.map((element, index) => {
                return (
                  <li key={index}>
                    <a className="nav-bar-button" href={element.href}>
                        <span className="menu-title">{element.title}</span>
                        <span className="menu-description">
                          {element.description}
                        </span>
                    </a>
                  </li>
                )
              })}
            </ul>
           </div>
        </div>}
    </div>
  );
}

function JoinWaitlistBarButton({ title, href }) {
  return (
    <div className="nav-bar-button-container nav-bar-download round-gradient d-flex ml-30">
      <a className="nav-bar-button white-small-text" href={href}>
        {title}
      </a>
    </div>
  );
}

function MobileMenuButton() {
  const onClick = () => {
    // Show hide menu container
    $("#mobile-menu-container").toggle();
    $("#desktop-navigation-bar").toggle();
    $("#navigation-bar-container-outter-view").toggleClass("full-height");
  };

  return (
    <div className="mobile-button-container d-flex" onClick={onClick}>
      <img
        src={menuimg}
        className="menu-button-mobile d-flex align-self-center"
        alt="menu"
      />
    </div>
  );
}

export default function NavigationBar() {
  return (
    <div className="navigation-bar">
      <div className="container navigation-bar-container">
        <div className="row ps-3 pe-4">
          <div className="col-2 logo-column align-self-center">
            <a href="/">
              <img src={logoimg} className="logo d-flex" alt="logo" />
            </a>
          </div>
          <div className="col">
            <div className="navigation-actions-desktop">
              <div className="d-flex flex-row flex--justify-center">
                <NavBarButton title="Resources" href="#" children={children} />
                <NavBarButton title="At Work" href="/work" />
                <NavBarButton title="Our Why" href="/ourwhy" />
                <JoinWaitlistBarButton
                  title="Download app"
                  href="https://beurzbyte.page.link/app"
                />
              </div>
            </div>

            <div className="navigation-actions-mobile">
              <div className="d-flex flex-row-reverse">
                <MobileMenuButton />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
