import React from 'react'

const WorkinfoCard = ({ img, text, title }) => {
    return (

        <div className='workInfoCard-bg position-relative '>
            <div className='container ' >
                <div className='row'>
                    <div className='col-lg-10 mt-3'>
                        <h1 className='work-infoCard-title'>{title}</h1>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-9 '>
                        <p className='work-infoCard-text mt-3'>{text}</p>
                        <img className='work-infoCard-avatar ' src={img} alt=""></img>
                    </div>
                </div>

            </div>
        </div>

    )
}

export default WorkinfoCard