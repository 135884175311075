import React from "react";

const ArticleContainer = ({ title, date, subtitle, description, page }) => {
  return (
    <div className="article-container">
      <div className="article-border">
        <div className="article-content  p-4 d-flex flex-column justify-content-between ">

          <div className="row">
            <div className="row ">
              <div className="col overflow-hidden d-flex justify-content-center">
                <img src={title} className="press-img" alt=""></img>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <h2 className="article-date">{date}</h2>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h2 className="article-subtitle">{subtitle}</h2>
                <p className="article-description mt-3">{description}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <a href={`${page}`} target="_blank" rel="noopener noreferrer">
                <h2 className="article-readBtn" >Read Now</h2>
              </a>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
};

export default ArticleContainer;
