import footerlogo from "../resources/img/footer-logo.png";
import instagramlogo from "../resources/img/Instagram.svg";
import mediumlogo from "../resources/img/Medium.svg";
import tiktoklogo from "../resources/img/TikTok.svg";
import linkedinlogo from "../resources/img/LinkedIn.svg";
import youtubelogo from "../resources/img/YouTube.svg";
import bCorpLogo from "../resources/img/certified-b-corp.png";

function FooterLinkWithImage({ className, img, href, text, target }) {
  return (
    <div className={"footer-link-container  " + (className ? className : "")}>
      <a className="footer-link-href" target={target} href={href}>
        <img src={img} className="footer-icon" alt="social-icon" />
        <span className="footer-link-text">{text}</span>
      </a>
    </div>
  );
}

function FooterLinkSimple({ className, href, text, target }) {
  return (
    <div className={"footer-link-container " + (className ? className : "")}>
      <a className="footer-link-href" target={target} href={href}>
        <span className="footer-link-text">{text}</span>
      </a>
    </div>
  );
}

function FooterLink({ className = null, img, href, text, target = '_self'}) {
  if (img != null) {
    return (
      <FooterLinkWithImage
        className={className}
        img={img}
        href={href}
        text={text}
        target={target}
      />
    );
  }

  return <FooterLinkSimple className={className} href={href} text={text} target={target} />;
}

export default function Footer() {
  return (
    <div className="footer-container position-relative z-index2">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="row">
              <div className="footer-logo-container col-3">
                <img
                  src={footerlogo}
                  className="footer-logo"
                  alt="social-icon"
                />
              </div>
              <div className="col">
                <div className="row">
                  <FooterLink
                    className={"col footer-mobile-social-item"}
                    href={"https://www.instagram.com/beurzbyte/"}
                    text={""}
                    img={instagramlogo}
                  />
                  <FooterLink
                    className="col footer-mobile-social-item"
                    href={"https://www.tiktok.com/@beurzbyte"}
                    text={""}
                    img={tiktoklogo}
                  />
                  <FooterLink
                    className="col footer-mobile-social-item"
                    href={
                      "https://www.youtube.com/channel/UCB6Qk70rsJ0SWQ_UC-Xnyxw"
                    }
                    text={""}
                    img={youtubelogo}
                  />
                  <FooterLink
                    className="col footer-mobile-social-item"
                    href={"https://www.linkedin.com/company/beurzbyte/"}
                    text={""}
                    img={linkedinlogo}
                  />
                  <FooterLink
                    className="col footer-mobile-social-item"
                    href={"https://medium.com/@beurzbyte"}
                    text={""}
                    img={mediumlogo}
                  />
                </div>
              </div>
            </div>
            <div className="footer-info-container">
              <div className="footer-texts">
                <div className="small-text">
                  Beurzbyte Ltd<br></br>Co. 13043092
                </div>
                <div className="small-text mt-20">
                  100 Bollo Lane, W4 5LX, London, United Kingdom
                </div>
              </div>

              <div className="b-corp-footer">
                <img
                  src={bCorpLogo}
                  className="b-corp-logo"
                  alt="social-icon"
                />
              </div>
            </div>
          </div>
          <div className="col offset-lg-1">
            <div className="row">
              <div className="col social-footer-container-col">
                <div className="social-footer-container">
                  <div className="footer-title">Social</div>
                  <FooterLink
                    href={"https://www.instagram.com/beurzbyte/"}
                    text={"Instagram"}
                    img={instagramlogo}
                  />
                  <FooterLink
                    href={"https://www.tiktok.com/@beurzbyte"}
                    text={"TikTok"}
                    img={tiktoklogo}
                  />
                  <FooterLink
                    href={
                      "https://www.youtube.com/channel/UCB6Qk70rsJ0SWQ_UC-Xnyxw"
                    }
                    text={"YouTube"}
                    img={youtubelogo}
                  />
                  <FooterLink
                    href={"https://www.linkedin.com/company/beurzbyte/"}
                    text={"LinkedIn"}
                    img={linkedinlogo}
                  />
                  <FooterLink
                    href={"https://medium.com/@beurzbyte"}
                    text={"Medium"}
                    img={mediumlogo}
                  />
                </div>
              </div>

              <div className="col">
                <div className="beurzbyte-footer-container">
                  <div className="footer-title">Beurzbyte</div>
                  <FooterLink href={"/ourwhy"} text={"Our Why"} />
                  <FooterLink
                    href={"https://beurzbyte.teamtailor.com/"}
                    text={"Careers"}
                  />
                  <FooterLink
                    href={"https://beurzbyte.teamtailor.com/pages/ambassadors"}
                    text={"Ambassadors"}
                  />
                  <FooterLink href={"/work"} text={"Beurzbyte at work"} />
                  <FooterLink href={"/press"} text={"Press"} />
                </div>
              </div>

              <div className="col">
                <div className="support-footer-container">
                  <div className="footer-title">Support</div>
                  <FooterLink href={"/faq"} text={"FAQ"} />
                  <FooterLink
                    href={"/terms-and-conditions"}
                    text={"Terms and Conditions"}
                  />
                  <FooterLink
                    href={"https://app.privasee.io/privacy-center/64088427e45ace00134369ce"}
                    text={"Privacy Policy"}
                    target={"_blank"}
                  />
                  <FooterLink 
                    href={"https://app.privasee.io/privacy-center/64088427e45ace00134369ce"}
                   text={"Cookie Policy"}
                   target={"_blank"} />
                  <FooterLink
                    href={"mailto:info@beurzbyte.io"}
                    text={"Contact us"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <div className="mobile-footer-info-container mt-40">
              <img
                src={bCorpLogo}
                className="b-corp-logo-mobile"
                alt="social-icon"
              />
            </div>
          </div>
          <div className="col-6">
            <div className="mobile-footer-info-container mt-40">
              <div className="small-text">
                Beurzbyte Ltd<br></br>Co. 13043092
              </div>
              <br></br>
              <div className="small-text">
                100 Bollo Lane, W4 5LX, London, United Kingdom
              </div>
            </div>
          </div>
        </div>

        {/* Other row */}
        <div className="row pt-30">
          <div className="col-lg-2">
            <div className="copyright-text">
              Copyright © {new Date().getFullYear()} Beurzbyte Ltd{" "}
              <br className="footer-space"></br>
              All rights reserved.
            </div>
          </div>
          <div className="col offset-lg-3">
            <div className="row">
              <div className="disclaimer-txt">
                Beurzbyte is for informational and educational purposes only and
                does not constitute financial, investment, or tax advice.
                Beurzbyte is not regulated by the Financial Conduct Authority
                and does not offer or solicit to buy or sell any security.
                Beurzbyte is not a broker, accountant, portfolio manager, or
                financial advisor. We disclaim all liability and responsibility
                arising from any reliance placed on the information on our
                website, mobile app, or any other material produced by Beurzbyte
                by any user, or by anyone who may be informed of any of its
                contents.<br></br>
                <br></br>
                You understand and agree that the information provided on our
                website, platform, mobile app, or any other material produced by
                Beurzbyte does not constitute any form of advice,
                recommendation, endorsement or arrangement. Past performance
                does not guarantee future results or returns. As always, with
                investing, capital is at risk, including the possible loss of
                capital.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
