import React from "react";
import downloadbutton from "../../resources/img/download-button.svg";
import phonesImg from "../../resources/img/our-why-2.png";
import owGetStartedBtn from "../../resources/img/ow-GetStartedBtn.svg";
import SubscribeToOurNewsletter from "../SubscribeToOurNewsletter";

const Fold4 = () => {
  return (
    <div className="ow-dark  padding10vhtop padding10vhbottom ">
      <div className="container">
        <div className="row rev">
          <div className="col-lg-6 img-container ">
            <img src={phonesImg} alt="phones" className="img-fluid" />
          </div>
          <div className="col-lg-6  d-flex flex-column justify-content-center">
            <h1 className="bb-title color-white">
              Ready to discover the investor in you?
            </h1>
            <p className="header-description-container color-white mt-40">
              With an overload of social media platforms, fast online media,
              fake news, and click-bait, it can be hard to dissect signal from
              noise. Especially in times of uncertainty. With Beurzbyte, that’s
              all in the past. Cut through the noise. Take back control.
            </p>
            <a
              href="https://apps.apple.com/us/app/beurzbyte/id6443670820"
              className="header-description-container showOnBig"
            >
              <img
                src={downloadbutton}
                className="download-button"
                alt="menu"
              />
            </a>
            <a
              href="https://apps.apple.com/us/app/beurzbyte/id6443670820"
              className="header-description-container showOnSmall"
            >
              <img
                src={owGetStartedBtn}
                className="download-button"
                alt="menu"
              />
            </a>
          </div>
        </div>
        <div className="row d-flex align-items-center justify-content-center mt-5">
          <div className="col">
            <SubscribeToOurNewsletter theme={"subscribe-pink"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fold4;
