import React from "react";
import img1 from "../../resources/img/our-why-1.png";
import gradientLine from "../../resources/img/gradientLine.svg";

const Fold2 = () => {
  return (
    <div className="ow-fold2 padding10vhbottom pt-5">
      <div className="container ">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-7 d-flex justify-content-center">
            <h1 className="title-centered mt-40">
              Introducing the Beurzbyte App
            </h1>
          </div>
        </div>

        <div className="row">
          <div className="col d-flex justify-content-center img-container">
            <img src={gradientLine} alt="line" className="img-fluid" />
          </div>
        </div>

        <div className="row d-flex justify-content-center">
          <div className="col-lg-6 d-flex justify-content-center text-center">
            <p>
              An intuitive mobile platform powered with the investing education
              and insights you deserve. Experience inspiring investment content
              like never before. At your own pace and on the go.
            </p>
          </div>
        </div>

        <div className="row d-flex justify-content-center rev">
          <div className="col-lg-6 d-flex flex-column justify-content-center align-items-start">
            <h1 className="ow-fold2-title ">
              Every investment <br></br>tells a story
            </h1>
            <p className="header-description-container">
              We combine the science and art of investing into powerful
              storytelling. Our engaging video content gives you the clarity you
              need to build your investment knowledge and discover
              opportunities. All in a matter of minutes and on the go.
            </p>
            <p className="header-description-container">
              We do the heavy lifting, so you don’t have to. We transform
              endless hours of investing education and investing insights into
              actionable content. Powered by our in-house team of investment
              professionals and creative storytellers.
            </p>
          </div>
          <div className="col d-flex justify-content-center">
            <img src={img1} alt="img" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fold2;
