import React from "react";

const SmallInfoCard = ({ title, description, icon }) => {
  return (
    <div
      className="small-infoCard-container d-flex flex-column
      "
    >
      <div className="row d-flex justify-content-center smallcardIcon">
        <img src={icon} alt="cardImg" className="small-card-image" />
      </div>
      <div className="row">
        <div className="col">
          <h1 className="small-infoCard-title">{title}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h2 className="small-infoCard-description">{description}</h2>
        </div>
      </div>
    </div>
  );
};

export default SmallInfoCard;
