import React from "react";

import webAsset from "../../resources/img/our-why-investment-web.png";
import mobileAsset from "../../resources/img/our-why-investment-mobile.png";
const OwSkew2 = () => {
  return (
    <div className="container padding10vhtop padding10vhbottom mt-40">
      <div className="row">
        <div className="col-lg-8">
          <h1 className="ow-fold2-title-light ">
            Navigate the investment landscape like never before.
          </h1>
          <p className="color-white mt-4">Empower your investment decisions.</p>
        </div>
        <div className="row mt-5">
          <div className="showOnBig">
            <div className="col investment-landscape-image">
              <img
                src={webAsset}
                alt=""
                className="img-fluid investment-landscape-image"
              />
            </div>
          </div>
          <div className="showOnSmall">
            <div className="col ">
              <img
                src={mobileAsset}
                alt=""
                className="img-fluid investment-landscape-image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OwSkew2;
