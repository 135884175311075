import React from "react";

const InfoCard = ({ title, description, icon, className }) => {
  return (
    <div className={`${className}`}>
      <div className="infoCard-container container p-5">
        <div className="row">
          <div className="col">
            <h1 className="infoCard-title">{title}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h2 className="infoCard-description">{description}</h2>
          </div>
        </div>
        <div className="cardIcon">
          <img src={icon} alt="cardIcon"></img>
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
