import React, { Children } from "react";
import gradientLine from "../../resources/img/gradientLine.svg";

import ControlledCarousel from "../ControlledCarousel";
import ReviewCardWork from "./ReviewCardWork";
import avatar7 from "../../resources/img/workCardsIcons/avatar7.png"
import img1 from "../../resources/img/workCardImg1.png"
import img2 from "../../resources/img/workCardImg2.png"
import img3 from "../../resources/img/workCardImg3.png"
import img4 from "../../resources/img/workCardImg4.svg"
import UnlockBenefitCard from "./UnlockBenefitCard";
import UnlockBenefitCardSmall from "./UnlockBenefitCardSmall";

const Wrapper = ({ children, className }) => {
  return (
    <div className={`col  ${className}`}>
      <div className="wrapper">
        {children}
      </div>
    </div>
  )
}

const Skew1 = () => {
  const reviewCard = {
    name: "Jurgen A.",
    position: "Entrepeneur/CEO",
    avatar: avatar7,
    description: "Beurzbyte Discover allows me to always stay informed about unique investment opportunities. Due to my busy schedule, I never really had time to look at multiple opportunities in different asset classes. "
  }

  return (
    <div className=" padding10vhbottom padding10vhtop">
      <div className="container padding10vhbottom padding10vhtop">
        <div className="row d-flex justify-content-center">
          <div className="col d-flex justify-content-center">
            <h1 className="work-bigCard-title">
              Explore premium investing <br></br>
              education like never before
            </h1>
          </div>
        </div>
        <div className="row ">
          <div className="col d-flex justify-content-center img-container">
            <img src={gradientLine} alt="line" className="img-fluid" />
          </div>
        </div>
        <div className="row d-flex justify-content-center ">
          <div className="col-lg-8 d-flex justify-content-center ">
            <p className="text-center">
              Implementing a financial literacy education programme in the workplace can benefit companies and employees by relieving stress, increasing productivity,  and even aiding recruiting, and retention.
            </p>
          </div>
        </div>

        <div className="row align-items-center showOnBig p-5 ">

          <Wrapper>
            <ReviewCardWork name={reviewCard.name}
              title={reviewCard.position}
              avatar={reviewCard.avatar}
              description={reviewCard.description}
            />
          </Wrapper>
          <Wrapper>
            <img className="wrapped-img" src={img1} alt=""></img>
          </Wrapper>
          <Wrapper className={""}>
            <img className="wrapped-img" src={img2} alt=""></img>
          </Wrapper>
          <Wrapper className={" mt-5"}>
            <img className="wrapped-img" src={img3} alt=""></img>
          </Wrapper>
          <Wrapper className={"mt-5 col-xl-8"}>
            <UnlockBenefitCard />
          </Wrapper>




        </div>

        <div className="showOnSmall ">
          <div className="row d-flex justify-content-center gap-5 ">
            <div className="row d-flex justify-content-center min-height400">
              <div className="col-md-6 col-sm-8 d-flex justify-content-center mt-5  ">
                <ControlledCarousel
                  variant={"dark"}
                  content={[
                    <Wrapper>
                      <ReviewCardWork name={reviewCard.name}
                        title={reviewCard.position}
                        avatar={reviewCard.avatar}
                        description={reviewCard.description}
                      />
                    </Wrapper>,
                    <Wrapper>
                      <img className="wrapped-img" src={img1} alt=""></img>
                    </Wrapper>,
                    <Wrapper>
                      <img className="wrapped-img" src={img2} alt=""></img>
                    </Wrapper>,
                    <Wrapper>
                      <img className="wrapped-img" src={img3} alt=""></img>
                    </Wrapper>,
                    <Wrapper>
                      <img className="wrapped-img" src={img4} alt=""></img>
                    </Wrapper>,

                    <Wrapper>
                      <UnlockBenefitCardSmall />
                    </Wrapper>



                  ]}
                />
              </div>

            </div>

          </div>
        </div>

      </div>

    </div>
  );
};

export default Skew1;
