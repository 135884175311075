import React from "react";
import teamImg from "../../resources/img/teamImg.png";
import lineDeco from "../../resources/img/line-decoration.svg";
const OwSkew1 = () => {
  return (
    <div className="container padding10vhtop padding10vhbottom mt-40">
      <div className="row">
        <div className="col img-container d-flex flex-column justify-content-center  ">
          <div className="row">
            <div className="col">
              <h1 className="ow-fold2-title ">Meet the Team</h1>
            </div>
          </div>
          <div className="row">
            <div className="col img-container">
              <img src={lineDeco} alt="" className="img-fluid "></img>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <p className="header-description-container mt-40">
                We started Beurzbyte in 2022 with the ambition to bring the
                world of investing to life.
              </p>
              <p className="header-description-container">
                At Beurzbyte, our best investment is our people. We are a
                passionate and diverse team of investment professionals,
                innovators, creators, designers, and artists. From our London
                office, we collaborate in a vibrant culture where the consumer
                is at the core of our decision-making.
              </p>
              <p className="header-description-container">
                We harness our combined expertise of having worked at some of
                the biggest investment funds, leading investment banks, global
                studios, and iconic brands to empower you with the knowledge and
                tools you deserve to make better investment decisions.
              </p>
              <p className="header-description-container">
                Designed collaboratively. Crafted passionately. Delivered with
                clarity.
              </p>
              <p className="header-description-container">
                We’re Beurzbyters. <br></br>
                <b>And we’re here for you.</b>
              </p>
            </div>
          </div>
        </div>


        <div className="col d-flex justify-content-center img-container ow-team-image">

          <img src={teamImg} alt="team" className="image-fluid" />
        </div>
      </div>
    </div>
  );
};

export default OwSkew1;
