import React from "react";
import fold1img from "../../resources/img/pressPage-fold1.png";
import pressBtn from "../../resources/img/pressBtn.svg";
import PressKit from "../../resources/downloads/BeurzbytePressKit.zip";

const Fold1 = () => {
  return (
    <div className="press-color-bg ">
      <div className="container position-relative">
        <div className="row position-absolute mt-lg-5 mt-3">
          <div className="row d-flex align-items-center h-100 justify-content-between">
            <div className="col-lg-5 col-10">
              <h2 className="press-small-title mt-lg-5 mt-3">
                BEURZBYTE PRESS
              </h2>
              <h1 className="bb-title color-white">
                Get company updates, press, coverage, and media assets
              </h1>
              <p className="color-white press-subtitle mt-lg-5 mt-3">
                Download our press kit and get in touch with us. Check out our
                progress below.
              </p>
            </div>
            <div className="row mt-lg-5 mt-3">
              <div className="col press-btn-container">
                <a
                  href={PressKit}
                  download="PressKit"
                  target="_blank"
                  className="empty-a"
                >
                  <button className="pressBtn ">Download our press kit</button>
                </a>
                <a href="mailto:info@beurzbyte.io" className="empty-a">
                  <button className="pressBtn-outlined">
                    <img src={pressBtn} alt="btn"></img>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col d-flex justify-content-end">
        <img src={fold1img} alt="press" className="press-bg-img"></img>
      </div>
    </div>
  );
};

export default Fold1;
