import React, { useState } from "react";
import plus from "../../resources/img/plus-button.png";
import minus from "../../resources/img/minus-button.png";
import parse from "html-react-parser";

const FaqQuestion = ({ title, description }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className="faq-question-container mt-5"
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="row mb-4">
        <div className="col">
          <h1 className="faq-question-title ">{title}</h1>
        </div>
        <div className="col-1 d-flex justify-content-end">
          <button className="faq-btn" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? (
              <img src={minus} className="faq-icon" alt="social-icon" />
            ) : (
              <img src={plus} className="faq-icon" alt="social-icon" />
            )}
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="row">
          <div className="col">
            <p className="faq-question-description mb-4">
              {parse(description)}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default FaqQuestion;
