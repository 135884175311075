import React from "react";
import FoldOne from "../FoldOne";
import FoldTwo from "../FoldTwo";
import LongBoard from "../LongBoard";
import Skew1 from "../Skew1";
import Skew2 from "../Skew2";
import Skew3 from "../Skew3";
import SkewContainer from "../SkewContainer";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import Fold2 from "../At Work/Fold2";

const HomePage = () => {
  const location = useLocation();

  React.useEffect(() => {
    // runs on location, i.e. route, change
    ReactGA.set({ page: location.pathname });
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return (
    <>
      <FoldOne />
      <Fold2 bg={"work-featured-bg2"} />

      <SkewContainer>
        <Skew1 />
      </SkewContainer>
      <Skew2 />
      <SkewContainer>
        <Skew3 />
      </SkewContainer>
      <LongBoard />
    </>
  );
};

export default HomePage;
